.cell-video {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    user-select: none;
    cursor: pointer;
  }

  .video_root{
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;
      /* background-color: #000000; */
 }

 .play_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
 }

 .volume_button {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%,-50%); */
  left: 4%;
  bottom: 4%;
  cursor: pointer;
}

#volume_icon {
  font-size: 20;
  fill: white;
  background-color: rgba(50, 50, 50, 0.3);
  border-Radius: 50%;
  padding: 3px;
  cursor: pointer;
}