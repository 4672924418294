/* Full css file at https://github.com/algolia/autocomplete/blob/next/packages/autocomplete-theme-classic/src/theme.scss */

.searchbox{
  margin-right: 5px;
  /* background-color: #f1f1f1; */
}

.aa-Panel{
  z-index: 9999 !important;
}

.aa-Autocomplete{
  width: 330px;
  border-radius: 15px;
}

.aa-Form{
  border-radius: 8px;
  --aa-primary-color-rgb: 163, 166, 168;
}

.aa-Form:focus-within {
    background-color: white;
    --aa-primary-color-rgb: 23, 120, 223;
}

.aa-ItemActionButton:last-child {
  display: none;
}

:root {
  --aa-base-unit: 16;
  --aa-spacing-factor: 0.9;
  --aa-search-input-height: 36px;

  --aa-primary-color-rgb: 23, 120, 223;
  --aa-primary-color-alpha: 0.2;

  --aa-input-border-color-alpha: 0.2;
  --aa-input-background-color-rgb: 246, 247, 248;

  --aa-selected-color-rgb: 180, 200, 244;
  --aa-selected-color-alpha: 0.2;


  }



@media only screen and (max-width: 680px) {
  :root {
    --aa-input-border-color-alpha: 0;
    --aa-input-background-color-alpha: 0;
    --aa-muted-color-alpha: 0;
    --aa-primary-color-rgb: 0, 0, 0;
  }

  .aa-ClearButton {
    color: rgba(var(--aa-muted-color-rgb), 1);
  }

}

@media only screen and (max-width: 800px) {
  .aa-Autocomplete{
    width: 240px;
  }

}

@media only screen and (max-width: 680px) {
  .aa-Autocomplete{
    width: 30px;
  }

  .aa-ItemActionButton svg{
    color: rgba(0, 0, 0, 1);
  }
}
