* {
  margin: 0;
}

body {
  overflow-y: scroll; /* Show vertical scrollbar */
  background-color: #fafafa;
}

/* hardcoded value to "md" breakpoint */
/* @media only screen and (max-width: 960px) { 
  body {
    background-color: rgb(218,220,222);
  }
} */

.rightPanel {
  flex: 1;
}

.rightPanel::-webkit-scrollbar {
  display: none;
}

.content {
  width: 100%;
  display: flex;
}

.post {
  margin-top: 20px;
  /* margin-bottom: 0px; */
}

@media only screen and (max-width: 960px) {
  .post {
    margin-top: 8px;
    /* margin-bottom: 14px; */
  }
}

.unselectable-text {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.movie-carosel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.movie-carosel {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

